import React from "react"
import SEO from '../components/seo'
import styled from "styled-components"
import Background from '../components/Background'
import Wrapper from '../components/Wrapper'
import Heading from '../components/Heading'
import Paragraph from '../components/Paragraph'
import Menu from '../components/Menu'
import IndexImage from '../components/IndexImage'
import ContactRow from "../components/ContactRow"

const ParagraphWrapper = styled.div`
  max-width: 600px;
  margin: auto; 

  & > p:nth-child(2) {
    margin-top: 10px;
  }
`

export default () => {
  return (
    <>
      <SEO
        title={ 'Freelance Web Development '}
        description={ 'My name is Bobby and I\'m a full stack web developer' }
        lang={ 'en' }
      />
      <Background>
        <Wrapper>
          <Heading>brester.tech</Heading>
          <ParagraphWrapper>
            <Paragraph>
              Hi, my name is Bobby and I'm a full stack web developer.
              My passion is providing superior quality and speed at no
              cost of visual beauty. 
            </Paragraph>
            <Paragraph>
              I'm goal-oriented and always looking for the next challenge.
              I prefer to work with clients long term and value the relationships we create.
            </Paragraph>
          </ParagraphWrapper>
          <IndexImage />
          <ContactRow />
          <Menu />
        </Wrapper>
      </Background>
    </>
  )
}
