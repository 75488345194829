import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Wrapper = styled.div`
  width: 100%; 
  max-width: 200px;
  max-height: 200px;
  margin: 40px auto;
  margin-top: 40px;
  border-radius: 50%;
  overflow: hidden;
`

export default () => {
  const imgData = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: {eq: "brestnichki_2.jpg"}) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }`
  );

  return (
    <Wrapper>
      <Img
        fixed={ imgData.file.childImageSharp.fixed }
      />
    </Wrapper>
  )
}